import React, { Component } from 'react';

import Modal from 'react-bootstrap/Modal';

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

class Ubicacion extends Component {
  componentWillMount () {
    window.H5_loading.show();
  }
  
  componentDidMount () {
    window.H5_loading.hide();
  }

  state = {
    show: false,
  }

  handleShow(){
    window.H5_loading.show();
    this.setState({ show: true });
    window.H5_loading.hide();
  };

  handleHide(){
    this.setState({ show: false });
  };

  render () {
      return (
        <div className="fondo_ubicacion">
          <div className="max_width padding_width at_c">
              
            <div className="degradados4 pa_degradados" data-aos="fade-right">
              <button to="calidad" className="texto_calidad_c" onClick={this.handleShow.bind(this)}>
                {
                  localStorage.idioma ? 
                    "PRIVILEGED LOCATION IN THE HEART OF MEXICO" 
                  : 
                    "PRIVILEGIADA LOCALIZACIÓN EN EL CORAZÓN DE MÉXICO"
                }
              </button>
              <div id="cl"><br/></div>
              <div to="calidad" className="texto_calidad" onClick={this.handleShow.bind(this)}>
                <h5>{localStorage.idioma ? "LOCATION" : "UBICACIÓN"}</h5>
              </div>
              <div id="cl"></div>
              <div className="ver_mas" onClick={this.handleShow.bind(this)}>+</div>
            </div>
              
          </div>

          <Modal
            show={this.state.show}
            onHide={this.handleHide.bind(this)}
            dialogClassName="modal-90w"
            className="modal_fer"
            aria-labelledby="example-custom-modal-styling-title">

            <Modal.Body className="modal_cuadro" data-aos="fade-down">
              <div className="bg_azulSedeco padding_location">
                <center>
                  <h100><h6>
                    {
                      localStorage.idioma ? 
                        "PRIVILEGED LOCATION IN THE HEART OF MEXICO" 
                      : 
                        "PRIVILEGIADA LOCALIZACIÓN EN EL CORAZÓN DE MÉXICO"
                    }
                  </h6></h100>
                  <br/>
                  {
                    localStorage.idioma ? 
                      "Immediate access to the Mexico City Metropolitan Area of the Valley of Mexico (MCMA), the main economic, financial, political and cultural center of the country." 
                    : 
                      "Acceso inmediato a la Zona Metropolitana del Valle de México (ZMVM) el principal centro económico, financiero, político y cultural del país."
                  }
                  <br/><br/>
                </center>
                <div className="degradados4 img_location">
                  <img src={`${window.rutas}img/${localStorage.idioma ? "location_in" : "location"}.png`} alt="Location"/>
                </div>
              </div>
              {
                window._my_ubicacion.map((item, i) =>
                  <div>
                    <div className="cuadro_ubica" style={{backgroundImage:"url('"+item.fondo+"')"}}>
                      <div className="de_cuadro_parques">
                        <div className="jh-close close-button" onClick={this.handleHide.bind(this)}>x</div>
                        <div id="cl"><br/></div>
                        <div className="flex_ubica">
                          <div className="de_flex_ubica">
                            <h100><h6>{localStorage.idioma ? item.nombreIn : item.nombre}</h6></h100>
                            <br/><br/>
                            <div dangerouslySetInnerHTML={{ __html: localStorage.idioma ? item.descripcionIn : item.descripcion }} className="texto_ubica"/>
                          </div>
                          <div className="de_flex_ubica_img">
                            <div className="t_ubica degradados">{localStorage.idioma ? item.subIn : item.sub}</div>
                            <div className="img_ubica" id={item.ubica} style={{backgroundImage:"url('"+item.foto+"')"}}></div>
                            <div id="cl"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {
                      item.ubica==='p1_ubica' ? 
                        <div className="bg_azulSedeco padding_ruta">
                          <div className="degradados4 ruta_flex">
                            {
                              localStorage.idioma ? 
                                <div className="ruta_texto">
                                  Road infrastructure is strategic for access to the country's main ports and borders, which allows for greater dynamism in the regional economy.
                                  <br/><br/>
                                  <h6>Airports</h6>
                                  <div className="flex_aereopuertos">
                                    <img src="img/avion.svg" alt="Aereopuerto"/>
                                    Felipe Ángeles Intenational Airport
                                  </div>
                                  <div className="flex_aereopuertos">
                                    <img className="avion_azul" src="img/avion.svg" alt="Aereopuerto"/>
                                    México City Intenational Airport
                                  </div>
                                  <h6>Mexico City - Pachuca Train</h6>
                                  <img className="ruta_tren" src="img/ruta_tren.svg" alt="Train"/>
                                  <h6>Railways</h6>
                                  <img className="ruta_tren" src="img/ruta_vias.svg" alt="Railways"/>
                                  <h6>Roads</h6>
                                  <div className="flex_pc">
                                    <div className="pcx pc pc_1">
                                      <div className="pc_f pc_1"></div>
                                    </div>
                                    <div className="pc_texto">{localStorage.idioma ? "HIGHWAYS" : "AUTOPISTAS"}</div>
                                  </div>
                                  <div className="flex_pc">
                                    <div className="pcx pc2">
                                      <div className="pcz pc_f2"></div>
                                    </div>
                                    <div className="pc_texto">{localStorage.idioma ? "Arco Norte highway" : "Autopista Arco Norte"}</div>
                                  </div>
                                  <div className="flex_pc">
                                    <div className="pcx pc3">
                                      <div className="pcz pc_f3"></div>
                                    </div>
                                    <div className="pc_texto">{localStorage.idioma ? "Peripheral highway" : "Circuito Mexiquense"}</div>
                                  </div>
                                  <div className="flex_pc">
                                    <div className="pcx pc4">
                                      <div className="pcz pc_f4"></div>
                                    </div>
                                    <div className="pc_texto">{localStorage.idioma ? "México - Querétaro highway" : "Autopista México - Querétaro"}</div>
                                  </div>
                                  <div className="flex_pc">
                                    <div className="pcx pc5">
                                      <div className="pcz pc_f5"></div>
                                    </div>
                                    <div className="pc_texto">{localStorage.idioma ? "México - Pachuca highway" : "Autopista México - Pachuca"}</div>
                                  </div>
                                  <div className="flex_pc">
                                    <div className="pcx pc6">
                                      <div className="pcz pc_f6"></div>
                                    </div>
                                    <div className="pc_texto">{localStorage.idioma ? "México - Tuxpan highway" : "Autopista México - Tuxpan"}</div>
                                  </div>
                                </div>
                              : 
                                <div className="ruta_texto">
                                  La infraestructura carretera es estratégica para el acceso a los principales puertos y fronteras del país, que permite un mayor dinamismo en la economía regional.
                                  <h6>Aeropuertos</h6>
                                  <div className="flex_aereopuertos">
                                    <img src="img/avion.svg" alt="Aereopuerto"/>
                                    Aereopuerto Internacional Felipe Ángeles
                                  </div>
                                  <div className="flex_aereopuertos">
                                    <img className="avion_azul" src="img/avion.svg" alt="Aereopuerto"/>
                                    Aereopuerto Internacional de la CDMX
                                  </div>
                                  <h6>Tren CDMX - Pachuca</h6>
                                  <img className="ruta_tren" src="img/ruta_tren.svg" alt="Tren"/>
                                  <h6>Vías férreas</h6>
                                  <img className="ruta_tren" src="img/ruta_vias.svg" alt="Vías férreas"/>
                                  <h6>Carreteras</h6>
                                  <div className="flex_pc">
                                    <div className="pcx pc pc_1">
                                      <div className="pc_f pc_1"></div>
                                    </div>
                                    <div className="pc_texto">{localStorage.idioma ? "HIGHWAYS" : "AUTOPISTAS"}</div>
                                  </div>
                                  <div className="flex_pc">
                                    <div className="pcx pc2">
                                      <div className="pcz pc_f2"></div>
                                    </div>
                                    <div className="pc_texto">{localStorage.idioma ? "Arco Norte highway" : "Autopista Arco Norte"}</div>
                                  </div>
                                  <div className="flex_pc">
                                    <div className="pcx pc3">
                                      <div className="pcz pc_f3"></div>
                                    </div>
                                    <div className="pc_texto">{localStorage.idioma ? "Peripheral highway" : "Circuito Mexiquense"}</div>
                                  </div>
                                  <div className="flex_pc">
                                    <div className="pcx pc4">
                                      <div className="pcz pc_f4"></div>
                                    </div>
                                    <div className="pc_texto">{localStorage.idioma ? "México - Querétaro highway" : "Autopista México - Querétaro"}</div>
                                  </div>
                                  <div className="flex_pc">
                                    <div className="pcx pc5">
                                      <div className="pcz pc_f5"></div>
                                    </div>
                                    <div className="pc_texto">{localStorage.idioma ? "México - Pachuca highway" : "Autopista México - Pachuca"}</div>
                                  </div>
                                  <div className="flex_pc">
                                    <div className="pcx pc6">
                                      <div className="pcz pc_f6"></div>
                                    </div>
                                    <div className="pc_texto">{localStorage.idioma ? "México - Tuxpan highway" : "Autopista México - Tuxpan"}</div>
                                  </div>
                                </div>
                            }
                            <img src={`${window.rutas}img/${localStorage.idioma ? "ruta_in" : "ruta"}.png`} className="img_ruta" alt="Location"/>
                          </div>
                        </div>
                      :
                        null
                    }
                  </div>
                )
              }
            </Modal.Body>
          </Modal>
        </div>
      )
    
  }
}

export default Ubicacion;