import React, { Component } from 'react';
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'

class Carousel extends Component {
  componentWillMount () {
    window.H5_loading.show();
  }
  
  componentDidMount () {
    window.H5_loading.hide();
  }

  responsive = {
    0: { items: 1 },

    //1200: { items: 4 },
  }

  items = window._my_carousel
 
  state = {
    show: false,
    id: "",

    galleryItems: this.items.map((item, i) => {
      /*
      const entero = (i + 1) / 2
      let saber = 'anima_1'
      if(Number.isInteger(entero)===false){
        saber = 'anima_2'
      }
      */
      return(
        <div key={i} className="car_img" style={{backgroundImage: `url(${item.foto})`}}>
          {/*
              <div className="car_fondo">
                <div>
                  {item.texto ? <h8>{item.texto}</h8> : null}
                  {item.titulo ? <h9>{item.titulo}</h9> : null}
                  {item.www ? <a href={item.www} className="car_url" target="new">{item.www_decir}</a> : null}
                  {item.ir ? <Ir to={item.ir} className="car_url">{item.ir_decir}</Ir> : null}
                </div>
              </div>
            */}
        </div>
      )
    }),
  }

  render () {
      return (
        <div className="play_static">
          <a href={localStorage.idioma ? "https://investhidalgo.mx/img/videos/PS2.mp4" : "https://investhidalgo.mx/img/videos/PS1.mp4"} target="new" className="play_absolute hvr-push">
            <img src="https://investhidalgo.mx/img/slider/play.svg" title="Play" alt="Play"/>
          </a>
          {
            window._my_personal.length > 0 ?
              <AliceCarousel
                responsive={this.responsive}
                autoPlayInterval={5000}
                swipeDelta={0}
                autoPlay={true}
                fadeOutAnimation={true}
                mouseTrackingEnabtutoled={false}
                playButtonEnabled={false}
                disableAutoPlayOnAction={true}
                dotsDisabled={true}
                stopAutoPlayOnHover={false}
                buttonsDisabled={true}
                items={this.state.galleryItems}
                ref={(el) => (this.Carousel = el)}
              />
            :
              null
          }
        </div>
      )
    
  }
}

export default Carousel;